@import './mixins.scss';
@import './variables.scss';

.Inbox-List-Container {
    padding: 10px 0 15px 0 !important;

    @include xs {
        margin-bottom: 50px;
    }

    .Inbox-Item {
        padding: 20px 15px;
    }

    .ant-list-item {
        &:hover {
            cursor: pointer!important;
            background: #ddd!important;
        }
    }

    .ant-list-items {
        max-height: 330px;
        overflow-y: auto;

        @include sm {
            max-height: 400px;
        }

        @include md {
            max-height: 500px;
        }

        &::-webkit-scrollbar {
            position: absolute;
            width: 5px;
        }
        
        &::-webkit-scrollbar-track {
            background: #ECECEC;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: $lightBlueColor;
        }
    }

    .ant-list-pagination {
        margin-bottom: 20px;
        text-align: center;

        @include xs {
            margin-top: 10px;
        }

        @include sm {
            margin-top: 20px;
        }
    }
        
    .ant-list-item-meta-title {
        font-weight: 700;
    }

    .ant-list-item-meta-description {
        font-weight: 600;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.Message-Details-Card {
    min-height: 50vh;

    .Message-Subject {
        font-weight: 600;
        color: $primaryColor;
    }

    .Message-Text {
        color: #585858;
    }
}

.container-fluid {
    @include xs {
        padding-left: 0!important;
        padding-right: 0!important;
    }

    @include md {
        padding-right: 15px;
        padding-left: 15px;
    }
}