@import './_mixins.scss';

.ant-list {
    &.Appointments-List {
        background-color: transparent !important;
        box-shadow: none !important;
        padding: 0!important;
    }
}

.Appointment-Info-Container {
    padding: 0 !important;
}

.Appointment-Card {
    border-radius: 15px!important;
    margin-bottom: 20px!important;
    
    .ant-card-body {
        padding-left: 0;
        padding-right: 0;
        background-color: #fff;
        border-radius: 20px;

        @include xs {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .ant-card-actions {
        li {
            color: #2A9CFF !important;

            & span a:not(.ant-btn),
            .ant-card-actions > li > span > .anticon {
                color: #2A9CFF;
            }
        }   
    }

    ul.ant-card-actions {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.Appointment-Booking-Title {
    color: $primaryColor !important;
    font-weight: 700!important;
    font-size: 2.2rem;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.Attachment-Card-Title {
    color: $primaryColor !important;
    font-weight: 800!important;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Appointment-Detail-Title {
    color: $primaryColor !important;
    font-weight: 400;
}

.Appointment-Detail-Text {
    color: $secondaryLightTextColor !important;
    font-weight: 700;
    font-size: 2.5rem !important
}

.Appointment-Booking-Details {
    color: #193B68!important;
    font-size: 1.6rem;

    &.Location {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: unset!important;
    margin-bottom: 15px;
}

.ant-badge-count-sm {
    height: 22px!important;
    font-size: 17px!important;
    line-height: 23px!important;
    border-radius: 21px!important;
}

.Card-Gradient-Primary {
    background: rgb(4,70,143) !important;
    box-shadow: 0px 10px 10px 0px rgba(1, 111, 254, 0.2);
    border-radius: 20px!important;
    min-height: 170px;
    display: flex;
    align-items: center;

    @include xs {
        margin-bottom: 10px!important;
    }

    @include sm {
        margin-bottom: 10px!important;
    }

    .ant-card-body {
        width: 100%;
    }

    h4 {
        color: #fff!important;
    }
}

.Contact-Cancellation {
    margin-bottom: 15px;

    @include xs {
        text-align: left;
    }

    @include md {
        text-align: right;
    }

    h3 {
        @include xs {
            font-size: 1.7rem;
        }

        @include md {
            font-size: 1.4rem;
        }
    }
}

.Floating-Call-Button {
    border-radius: 50%;
    background: #35C235!important;
    border: none!important;
    box-shadow: 0 0 0 0 rgba(53, 194, 53, 0.7);
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;  
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)!important;
    transition: all 300ms ease-in-out;

    &:hover {
        -webkit-animation: none!important;
    }
}

.Modal-Message-Details {
    .ant-modal-body {
        padding: 0!important;
    }
}


/* Animation */

@-webkit-keyframes pulsing {
  to {
    
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.2);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 20px rgba(81, 255, 0, 0.1);
  }
}