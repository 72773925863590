@import './assets/fonts/Raleway/raleway.scss';
@import './styles/variables.scss';
@import './styles/_mixins.scss';
@import './styles/Appointments.scss';

html,
body {
    font-family: 'Raleway', sans-serif!important;
    // background-color: $bodyBackgroundColor;
}

#responsive-dialog-title {
    h2 {
        background: transparent!important;
    }
}

label {
    margin-top: 10px;
}

#wrapper {
    background-color: #fff;
    height: 100vh;
}

#basePage {
    padding-top: 30px;
}

#page-wrapper {
    // background-color: $bodyBackgroundColor!important;
    min-height: 100vh!important;
    transition: .2s;
    margin-top: 50px!important;
    margin-bottom: 70px!important;
}

.fivef-pageTitle {
    color: $primaryColor;
    font-weight: 800;
    margin: 20px 15px;

    @include xs {
        margin-bottom: 15px;
        margin-left: 0;
        margin-right: 0;
    }
}

.MuiPaper-root {
    border: 1px solid #ECECEC;
    box-shadow: none!important;
}

.MuiTableCell-head {
    color: $primaryColor!important;
    font-size: 15px!important;
    font-weight: 800!important;
}

.MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(50%, -35%) !important;   
}

.MuiBadge-invisible {
    display: none;
}

.File-Uploader-Button {
    background-color: #fff;
    border-radius: 50px;
    color: #034DA2;
    border: 1px solid #dcecff;
    padding: 15px 20px;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
}

.File-Upload-List {
    width: 100%;
    margin-top: 10px;
}

.Form-Dropdown .ant-select-selector {
    border-radius: 10px!important;
    width: 100%;
}

.ant-breadcrumb {
    a {
        color: $secondaryLightTextColor;
    }
}

.ant-modal-title {
    color: #4D4D4D !important;
    font-weight: 800 !important;
}

.ant-btn-link,
.ant-btn {
    border-radius: 20px !important;
}

.ant-btn-link,
.ant-btn {
    border-radius: 50px!important;
    font-weight: 700!important;

    .ant-btn-primary {
        background-color: $secondaryLightTextColor !important;
        -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
        -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
        box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    }

    @include xs {
        width: 100%;
        min-height: 50px;
    }

    @include md {
        min-width: 80px;
        min-height: 50px;
    }

    span {
        @include xs {
            font-size: 1.7rem;
        }

        @include md {
            font-size: 2rem;
        }
    }
}

.ant-btn-link {
    span {
        @include xs {
            font-size: 1.7rem;
        }

        @include md {
            font-size: 1.5rem;
        }
    }
}

.ant-badge-count {
    top: 5px!important;
    right: 2px!important;
}

.shadow-none {
    box-shadow: none;    
}

.ant-card {
    background: #fff;
}

.ant-card-grid {
    // -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    // -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    // box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    background-color: #fff;
}

.ant-list {
    background-color: #fff!important;
    padding: 10px!important;
    // -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    // -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    // box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
}

.Form-Input.ant-input,
.Form-DatePicker.ant-datepicker,
.Form-Dropdown {
    border-radius: 10px!important;
    margin-bottom: 5px!important;
    width: 100%;

    &.Error {
        border: 1px solid #dc3545!important;
    }

    @include xs {
        font-size: 1.8rem !important;
    }

    @include md {
        font-size: 2rem !important;
    }
}

.Error-Text {
    font-weight: 800;
    font-size: 14px;
}

.Form-Dropdown {
    .ant-select-selector {
        height: 44px !important;
    }

    .ant-select-selection-item {
        padding-top: 7px!important;
    }
}

.ant-select-dropdown .ant-select-item {
    font-size: 2rem !important;
}

.Card-Grids {
    .ant-card-body {
        padding: 0 15px;

        .row {
            .ant-card-grid {
                width: -webkit-fill-available!important;
            }
        }
    }
}

.sc-header {
    background-color: $secondaryLightTextColor !important;
}

.sc-launcher {
    background-color: $secondaryLightTextColor !important;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    bottom: 100px !important;

    .sc-open-icon,
    .sc-closed-icon {
        bottom: 100px !important;
    }
}

.sc-chat-window {
    z-index: 130;
}

.ant-list-item {
    align-items: flex-start !important;
}

.ant-select-selection-item {
    color: #4D4D4D
}

/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.modal-content {
    width: 800px;
    height: 600px;
}

.ant-message {
    z-index: 99999!important;
}

.MuiDialog-paper {
    overflow-x: hidden!important;
}

.MuiDialog-root {
    z-index: 200;
}

.Form-Craft-Submit {
    @include xs {
        margin-left: 0!important;
    }

    @include md {
        margin-left: -13px!important;
    }
}

.table-row-read {
    background-color: #F5F5F5;
}

.table-row-unread {
    font-weight: bold;
}

// Ignores color styling for a badge
.ant-badge {
    color: inherit !important;
}

.decline-button {
    color: $lightBlueColor;
}

// Antd Error Popup Style
.ant-message-custom-content.ant-message-error {
    display: flex;
    align-items: center;
}

.TNC-Link-Button {
    padding: 0!important;
}

.ant-select-dropdown-placement-bottomLeft {
    z-index: 99999999!important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: rgba(56, 27, 68, 0.5) !important;
}

.ant-table-thead > tr > th,
.ant-table-container table > thead > tr:first-child th:last-child {
    background-color: transparent!important;
    font-weight: 700!important;
}