@import './variables.scss';
@import './_mixins.scss';

.fivef-navbar {
    background-color: #fff!important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 120;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.18);

    .fivef-navbar-brand {
        color: $primaryColor;
        width: 270px;
        font-weight: 800;
    }
}

.fivef-user-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .dropdown-menu {
        border: none;
        border-radius: 5px;
    }

    .dropdown-item {
        font-size: 16px;
    }
}

.btn-toggle-mb-sidebar {
    // height: 100%!important;
    position: absolute!important;
    top: 5px !important;
    right: 8px !important;
    border: 0!important;
    border-radius: 0!important;
}

#page-wrapper {
    @include xs {
        margin-left: 0;
    }

    @include sm {
        margin-left: 0;
    }

    @include md {
        margin-left: 0;
    }

    // Large devices
    @include lg {
        margin-left: 290px;
    }

    // Extra large devices
    @include xl {
        margin-left: 290px;
    }  
}

.sidebar-hidden {
    @include xs {
        margin-left: 0;
    }

    @include sm {
        margin-left: 0;
    }

    @include md {
        margin-left: 0;
    }

    // Large devices
    @include lg {
        margin-left: 0 !important;
    }

    // Extra large devices
    @include xl {
        margin-left: 0 !important;
    }
} 

.sidebar {
    background-color: #fff;
    height: 120vh!important;
    margin-top: 25px!important;
    transition: .2s;

    @include xs {
        position: absolute;
        left: -290px;
        display: none!important;
    }

    @include sm {
        position: absolute;
        left: -290px;
        display: none!important;
    }

    @include md {
        position: absolute;
        left: -290px;
        display: none!important;
    }

    @include lg {
        left: -290px;
        display: block!important;
    }

    @include xl {
        left: -290px;
        display: block!important;
    }
    
    
    &.active {
        @include xs {
            position: absolute;
            z-index: 99;
            width: 100vw;
            left: 0;
        }

        @include sm {
            position: absolute;
            z-index: 99;
            width: 100vw;
            left: 0;
        }

        @include md {
            z-index: 99;
            width: 290px;
            left: 0;
        }

        // Large devices
        @include lg {
            z-index: 99;
            width: 290px;
            left: 0;
        }

        // Extra large devices
        @include xl {
            z-index: 99;
            width: 290px;
            left: 0;
        }
    }

    ul {
        li {
            border: none!important;
            margin: 5px 10px!important;

            &.Sidebar-Sign-In {
                margin-top: 20px !important;
                margin-left: 25px !important;
            }

            a {
                padding: 12px 15px;
                color: #0170FE;

                &:hover {
                    background-color: #F3F8FF;
                    border-radius: 7px!important;
                }

                &.active {
                    background-color: #0170FE!important;
                    border-radius: 7px!important;
                    color: #fff!important;
                    font-weight: 800!important;
                    -webkit-box-shadow: 0px 10px 10px 0px rgba(1,111,254,0.2);
                    -moz-box-shadow: 0px 10px 10px 0px rgba(1,111,254,0.2);
                    box-shadow: 0px 10px 10px 0px rgba(1,111,254,0.2);
                }
            }
        }
    }
}

#responsive-navbar-nav {
    @include xs {
        display: none !important;
    }
    
    @include sm {
        display: none !important;
    }

    @include md {
        display: none !important;
    }

    @include lg {
        display: flex !important;
    }

    @include xl {
        display: flex !important;
    }
}


.MuiBottomNavigationAction-root.Mui-selected {
    color: $secondaryLightTextColor !important;
}

.MuiBottomNavigationAction-label {
    font-size: 1.20rem!important;
    text-align: center;
}

.MuiBottomNavigationAction-label.Mui-selected {
    font-size: 1.20rem !important;
    text-align: center;
    font-weight: 700;
    font-family: 'Raleway', sans-serif!important;
}

.MuiBottomNavigation-root {
    border-top: 1px solid #ddd;
}

.navbar-light .navbar-nav .nav-link {
    color: #2A9CFF;
}