@import './mixins.scss';
@import './variables.scss';

.Attachment-Card-Title {
    color: $primaryColor !important;
    font-weight: 800!important;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Attachment-Details {
    padding: 30px 0;
}

.Download-Attachment-Button {
    &:hover {
        cursor: pointer;
    }
}